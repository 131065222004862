@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "TestSöhne";
    src: url("../public/assets/font/TestSöhneBreit/test-soehne-breit-leicht.woff2");
  }
}

.darkBody {
  background-color: #2e2e2e;
  color: #fcfcfc;
}

/* LANDING PAGE EASE IN */
@keyframes easeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
* {
  scrollbar-color: #d8d8d8 transparent;
  scrollbar-width: thin;
}

.scrollbarBug {
  padding-left: calc(100vw - 100%);
}
.heroSection {
  animation: easeIn 0.3s ease-in-out;
  transition: all ease;
}

/* LOGO ANIMATION */
.letter {
  opacity: 0;
  transform: translateY(200px);
}
.logo-container {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  z-index: 10000;
  padding: 40px;
}
.logo_animate {
  transform-origin: center;
  transform: translateY(70vh);
  z-index: 1000;
  color: #000;
  width: 100vw;
}
/* END LOGO ANIMATION */

/* LOADER */

/* block scroll when loader is visible */
.no-scroll {
  overflow: hidden !important;
}
.animate {
  animation: animateBg 5s ease-in-out forwards;
}
@keyframes animateBg {
  from {
    opacity: 1;
    background-color: #2e2e2e;
  }
  to {
    opacity: 0;
    background-color: #2e2e2e;
  }
}
@keyframes animateLogo {
  0% {
    bottom: -50px;
    opacity: 0;
  }
  100% {
    bottom: 0;
    opacity: 1;
  }
}
@keyframes exitLogo {
  0% {
    top: 0;
  }
  100% {
    top: -80px;
  }
}
.exit {
  animation: exitLogo 1s forwards !important;
}
svg.logo {
  position: relative;
  animation: animateLogo 1s forwards;
}

@keyframes moveGradient {
  0% {
    offset: 0;
  }
  100% {
    offset: 1;
  }
}

#grad1 stop {
  animation: moveGradient 2s forwards;
}

#grad2 stop {
  animation: moveGradient 2s forwards;
  animation-delay: 2s;
}

#grad3 stop {
  animation: moveGradient 2s forwards;
  animation-delay: 4s;
}

/* LOADER END */

@media (min-width: 640px) {
  .text-bottom-line {
    position: relative;
  }

  .text-bottom-line::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    mix-blend-mode: difference;
    background-color: #fcfcfc;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.75s cubic-bezier(0.19, 1, 0.22, 1);
  }

  .text-bottom-line:hover::after {
    transform: scaleX(1);
    transform-origin: left;
  }
}

.mail-emoji-hover {
  cursor: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='40' height='48' viewport='0 0 100 100' style='fill:black;font-size:24px;'><text y='50%'>📩</text></svg>")
      16 0,
    auto;
}

.eyes-emoji-hover {
  cursor: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='40' height='48' viewport='0 0 100 100' style='fill:black;font-size:24px;'><text y='50%'>👀</text></svg>")
      16 0,
    auto;
}

.girl-emoji-hover {
  cursor: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='40' height='48' viewport='0 0 100 100' style='fill:black;font-size:24px;'><text y='50%'>👩🏻‍💻</text></svg>")
      16 0,
    auto;
}

/* PROJECT IMAGE CONTAINER ANIMATION */

.project-image-container::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0.5%;
  height: 0.5%;

  background: rgba(33, 31, 31, 0.5);
  backdrop-filter: blur(20px);

  border-radius: 50%;
  transform: translate(-50%, -50%);
  transition: width 0.9s ease-in-out, height 0.9s ease-in-out;
}

.project-image-container:hover::before {
  width: 1000px;
  height: 1000px;
  transition: width 0.9s cubic-bezier(0.7, 0, 0.31, 1),
    height 0.9s cubic-bezier(0.7, 0, 0.31, 1);
}

.project-image-container-text {
  position: absolute;
  bottom: 40%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: bottom 0.8s ease, opacity 0.8s ease;
  color: #fcfcfc;
}

.project-image-container:hover .project-image-container-text {
  bottom: 45%;
  opacity: 1;
}

/* CASE STUDY STYLES  */
.case_study {
  animation: expandFromCircle 0.8s ease-in-out forwards;
  transform-origin: top right;
}

.case_study.closing {
  animation: collapseToCircle 0.8s ease-in-out forwards;
  transform-origin: top right;
}

@keyframes expandFromCircle {
  0% {
    clip-path: circle(0% at top right);
  }
  100% {
    clip-path: circle(150% at top right);
  }
}

@keyframes collapseToCircle {
  0% {
    clip-path: circle(150% at top right);
  }
  100% {
    clip-path: circle(0% at 140% -100%);
  }
}

/* Smooth background overlay animation */
.background-overlay {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  transition: opacity 0.5s ease-out;
  cursor: pointer;
}

.background-overlay.closing {
  opacity: 0;
}
.cover_on_close {
  display: none;
}
